<template>
    <el-dialog
      :title="dialogTitle"
      :visible="lockDialogShow"
      :append-to-body="true"
      width="300px"
      :before-close="beforeCloseHandle"
    >
      <p class="content">{{content}}</p>
      <div class="footer-btn">
        <el-button type="primary"
                   @click="cancelHandle"
        >取消</el-button>
        <el-button type="success"
                   @click="sureHandle"
        >确认</el-button>
      </div>
    </el-dialog>
</template>

<script>
  import { lockHead } from '@/api/community/srCommunityUserInfo'
  export default {
    name: 'soldOutDialog',
    props:{
      lockDialogShow:{
        type: Boolean,
        default: false,
      },
      isLock:{
        type: Boolean,
      },
      dataId:{}
    },
    computed:{
      dialogTitle(){
        return this.isLock ? '锁定头像' : '解锁头像';
      },
      content(){
        return this.isLock
          ? '该账户将被锁定为系统默认头像,被锁定用户不可自行修改'
          : '解除后用户可更换头像'
      }
    },
    methods: {
      beforeCloseHandle() {
        this.$emit('close-dialog')
      },
      cancelHandle(){
        this.beforeCloseHandle();
      },
      sureHandle(){
        // ajax
        lockHead({
          lock: this.isLock == 1 ? 0 : 1,
          id: this.dataId,
        }).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.$parent.init();
          setTimeout(() => {
            this.beforeCloseHandle();
          },1000)
        })
      }
    }
  }
</script>

<style scoped>
.footer-btn {
  margin-top: 20px;
  text-align: center;
}
  .content {
    line-height: 1.5;
    font-size: 20px;
     text-align: center;
  }
</style>
