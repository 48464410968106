<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="600px">
    <el-form ref="form" :model="form"  size="small" label-width="120px">
      <el-form-item label="社区ID">
        <el-input v-model="form.siteId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="社区名称">
        <el-input v-model="form.siteName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="用户名/昵称">
        <el-input v-model="form.userName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="头像">
        <el-input v-model="form.portraitImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="国籍编码">
        <el-input v-model="form.nationality" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="性别">
        <el-input v-model="form.gender" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件类型">
        <el-input v-model="form.certType" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件号码">
        <el-input v-model="form.certId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="生日">
        <el-input v-model="form.birthday" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件正面图片">
        <el-input v-model="form.certValidatedImgFront" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件反面图片">
        <el-input v-model="form.certValidatedImgBack" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="邮箱地址">
        <el-input v-model="form.email" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="电话国家代码">
        <el-input v-model="form.nationCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.phone" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行名称">
        <el-input v-model="form.bankName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点省">
        <el-input v-model="form.bankOutletProvince" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点市">
        <el-input v-model="form.bankOutletCity" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点">
        <el-input v-model="form.bankOutlet" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡号">
        <el-input v-model="form.bankAccount" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡swiftCode">
        <el-input v-model="form.swiftCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡正面照片">
        <el-input v-model="form.bankFrontImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡反面照片">
        <el-input v-model="form.bankBackImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="简介">
        <el-input v-model="form.introduction" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="跟随开仓手数不足品种最小值">
        <el-input v-model="form.followOpenHandLessthanMin" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="跟随开仓手数">
        <el-input v-model="form.followOpenHandMorethanMin" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="跟随部分平仓">
        <el-input v-model="form.followPartClose" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-input v-model="form.createTime" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-input v-model="form.updateTime" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srCommunityUserInfo'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      rules:[],
      form: {
        id: '',
        siteId: '',
        siteName: '',
        userName: '',
        portraitImg: '',
        password: '',
        nationality: '',
        gender: '',
        certType: '',
        certId: '',
        birthday: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        nationCode: '',
        phone: '',
        bankName: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        introduction: '',
        followOpenHandLessthanMin: '',
        followOpenHandMorethanMin: '',
        followPartClose: '',
        remark: '',
        createTime: '',
        updateTime: ''
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteId: '',
        siteName: '',
        userName: '',
        portraitImg: '',
        password: '',
        nationality: '',
        gender: '',
        certType: '',
        certId: '',
        birthday: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        nationCode: '',
        phone: '',
        bankName: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        introduction: '',
        followOpenHandLessthanMin: '',
        followOpenHandMorethanMin: '',
        followPartClose: '',
        remark: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
