import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'community/crm/tCommunityUserInfo',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'community/crm/tCommunityUserInfo/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'community/crm/tCommunityUserInfo',
    method: 'put',
    data
  })
}

export function lockHead(data) {
  return request({
    url: '/community/crm/tCommunityUserInfo/lock',
    method: 'put',
    data
  })
}

export function editStrategyStatus(data) {
  return request({
    url: '/community/crm/srCommunityMtaccount',
    method: 'put',
    data
  })
}

// 获取下列返佣级别列表
export function getReturnComissionList(data) {
  return request({
    url: '/community/crm/getLevels',
    method: 'get',
    params: data
  })
}

// 获取客户收款信息
export function getPayUser(data) {
  return request({
    url: '/community/crm/getPayUser',
    method: 'get',
    params: data
  })
}

// 获取客户拒绝原因
export function getReasonList(data) {
  return request({
    url: '/community/crm/getRejectionReason',
    method: 'get',
  })
}

// 更新用户返佣级别
export function updateUserLevel(data) {
  return request({
    url: '/community/crm/updateUserLevel',
    method: 'post',
    data,
  })
}




















