<template>
  <el-dialog
    :append-to-body="true"
    :before-close="beforeClose"
    :visible="dialogShow"
    title="返佣设置"
    width="360px"
    class="commission-setting"
  >
    <el-form
      ref="form"
      :model="form"
      size="small"
      label-width="120px"
    >
      <el-form-item label="所属社区">
        <p class="form-p">{{userInfo.siteName}}</p>
      </el-form-item>
      <el-form-item label="昵称">
        <p class="form-p">{{userInfo.userName}}</p>
      </el-form-item>
      <el-form-item label="返佣">
        <el-select
          v-model="form.proportion"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="(item,index) in commissionList"
            :key="index"
            :label="`${item.levelName} (${item.proportion *100})%`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateUserLevel } from '@/api/community/srCommunityUserInfo'
export default {
  props: {
    dialogShow: {
      type: Boolean,
      required: false
    },
    userInfo:{
      type: Object,
      default(){
        return {

        }
      }
    },
    commissionList:{
      type: Array,
      default(){
        return [];
      }
    },
  },
  data() {
    return {
      loading: false,
      rules:[],
      form: {
        id: '',
        siteName: '',
        userName: '',
        proportion: '',
      }
    }
  },
  watch: {
    userInfo(newValue) {
      if(newValue){
        this.form.proportion = newValue.levelId;
      }
    }
  },
  methods: {
    close(){
      this.$emit('close');
    },
    beforeClose(){
      this.close();
    },
    cancel() {
      this.close();
    },
    doSubmit() {
      this.loading = true;
      updateUserLevel({
        userId: this.userInfo.id,
        levelId: this.form.proportion,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.close();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.commission-setting {
  .form-p {
    padding: 0;
    margin: 0;
  }
}
</style>
