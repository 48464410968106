<template>
  <el-row class="row-el">
    <el-col class="mar-bot10 qrcode-col" :span="12">
      <span class="label">支付宝收款码</span>
      <span>
        <el-image
          v-if="alipaInfo.url"
          class="qrcode-el"
          :src="alipaInfo.url"
          :preview-src-list="[alipaInfo.url]"
        ></el-image>
        <span v-else>-</span>
      </span>
    </el-col>
    <el-col class="mar-bot10 qrcode-col" :span="12">
      <span class="label">微信收款码: </span>
      <span>
        <el-image
          v-if="wetchaInfo.url"
          :src="wetchaInfo.url"
          :preview-src-list="[wetchaInfo.url]"
          class="qrcode-el"
        ></el-image>
        <span v-else>-</span>
      </span>
    </el-col>
    <el-col class="mar-bot20" :span="24">
      <span class="label">钱包账号: </span>
      <span>{{walletInfo.account || '-'}}</span>
    </el-col>
    <el-col class="mar-bot20" :span="24">
      <span class="label">钱包地址: </span>
      <span>{{walletInfo.address || '-'}}</span>
    </el-col>
    <el-col class="mar-bot10" :span="24">
      <span class="label">钱包二维码: </span>
      <span>
        <el-image
          v-if="walletInfo.url"
          :src="walletInfo.url"
          :preview-src-list="[walletInfo.url]"
        ></el-image>
        <span v-else>-</span>
      </span>
    </el-col>
    <el-col class="mar-bot20" :span="24">
      <span class="label">USDT地址: </span>
      <span>{{usdtInfo.address || '-'}}</span>
    </el-col>
    <el-col class="mar-bot10" :span="24">
      <span class="label">USDT二维码: </span>
      <span>
        <el-image
          class="usdt-qrimg"
          v-if="usdtInfo.url"
          :src="usdtInfo.url"
          :preview-src-list="[usdtInfo.url]"
        ></el-image>
        <span v-else>-</span>
      </span>
    </el-col>
  </el-row>
</template>

<script>
  import { getPayUser } from '@/api/community/srCommunityUserInfo'
  export default {
    name: 'payInfo',
    props: {
      userId: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        alipaInfo: {
          url: '',
        },
        wetchaInfo: {
          url: '',
        },
        walletInfo:{
          account: '',
          address: '',
          url: ''
        },
        usdtInfo:{
          address: '',
          url: ''
        },
      }
    },
    watch: {
      userId(newValue, oldValue) {
        this.getInfo(newValue);
      }
    },
    mounted(){
      if(this.userId){
        this.getInfo(this.userId);
      }
    },
    methods: {
      getInfo(id) {
        getPayUser({
          userId: id,
        }).then((res) => {
          console.log(res);
          if(res.length){
            for (let i = 0; i < res.length; i++) {

              switch (res[i].payTypeId) {
                case 1:
                  this.alipaInfo = res[i];
                  break;
                case 2:
                  this.wetchaInfo = res[i];
                  break;
                case 3:
                  this.walletInfo = res[i];
                  break;
                case 4:
                  this.usdtInfo = res[i];
                  break;
              }
            }
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.row-el {
  .qrcode-el {
    width: 120px;
  }
  .qrcode-col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .label {
      margin-right: 10px;
    }
  }
  .usdt-qrimg {
    width: 100px;
  }
}
</style>
