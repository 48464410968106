<template>
<div>
  <el-dialog :append-to-body="true" :visible.sync="dialog" title="用户详情" width="800px">
    <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <el-row >
            <el-col :span="8"><span class="label">所属社区:</span> <span>{{ data.siteName }}</span></el-col>
            <el-col :span="8"><span class="label">用户昵称: </span> <span>{{ data.userName}}</span></el-col>
            <el-col :span="8"><span class="label">客户状态:</span> <span>{{ data.status==0?'待完善资料':data.status==1?'正常':'锁定' }}</span></el-col>
          </el-row>
          <el-row >
            <el-col :span="8"><span class="label">邮箱: </span> <span>{{ data.email==null?'暂无': data.email}}</span></el-col>
            <el-col :span="8"><span class="label">电话号码:</span> <span>{{ data.phone==null?'暂无': data.phone }}</span></el-col>
            <el-col :span="8"><span class="label">性别:</span> <span>{{ data.gender==null?'暂无': data.gender==1?'男':'女' }}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">生日: </span> <span>{{ data.birthday==null?'暂无': data.birthday}}</span></el-col>
            <el-col :span="8"><span class="label">国籍: </span> <span>{{ data.nationality==null?'暂无': data.nationality==1?'中国':'美国'}}</span></el-col>
            <el-col :span="8"><span class="label">用户地址: </span> <span>{{ data.province==null?'暂无': data.province+'-'+data.city+'-'+data.street}}</span></el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="实名验证" name="second">
          <el-row>
            <el-col :span="12"><span class="label">姓名:</span> <span>{{ data.realName }}</span></el-col>
            <el-col :span="12"><span class="label">证件类型: </span> <span>{{ data.certType == 1 ? '身份证' : '护照' }}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="12"><span class="label">证件号码:</span> <span>{{ data.certId }}</span></el-col>
          </el-row>
          <el-row>
            <el-col>
              <span class="label">证件照片:</span>
              <img
                v-if="data.certValidatedImgFront"
                style="width: 170px;height: 120px"
                :src="data.certValidatedImgFront" @click="toImg(data.certValidatedImgFront)">
              <img v-if="data.certValidatedImgBack" style="width: 170px;height: 120px" :src="data.certValidatedImgBack" @click="toImg(data.certValidatedImgBack)">
            </el-col>
          </el-row>
        </el-tab-pane>
      <el-tab-pane label="支付信息" name="third">
        <pay-info
          :user-id="data.id"
        ></pay-info>
      </el-tab-pane>
        <el-tab-pane label="风控设置" name="four">
          <el-row>
            <el-col><span class="title">投资介绍:</span> <span>{{ data.introduction }}</span></el-col>
          </el-row>
          <el-row>
            <el-col><span class="text">跟随开仓手数不足品种最小值时:</span> <span>{{ parseAttr(data.followOpenHandLessthanMin) }}</span></el-col>
          </el-row>
          <el-row>
            <el-col><span class="text">跟随开仓手数大于品种最小值，但手数位数大于经纪商限制时: </span> <span>{{parseAttr(data.followOpenHandMorethanMin) }}</span></el-col>
          </el-row>
          <el-row>
            <el-col><span class="text">跟随部分平仓, 手数小数位大于经纪商限制时: </span> <span>{{parseAttr(data.followPartClose) }}</span></el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
  </el-dialog>
  <el-dialog :append-to-body="true" :visible.sync="imgUrlShow" width="800px">
    <img :src="imgUrl" width="100%">
  </el-dialog>
</div>
</template>

<script>
  import payInfo from './payInfo'
export default {
    components:{
      payInfo,
    },
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,activeName: 'first',imgUrl:'',
      imgUrlShow: false,
      data:{}
    }
  },
  methods: {
    toImg(imgUrl) {
      this.imgUrl=imgUrl;
      this.imgUrlShow=true;
    },
    cancel() {
      this.resetForm()
    },
    resetForm() {
      this.dialog = false
    },
    parseAttr(type){
      // todo
      switch(type){
        case 1 :
          return '取小';
        case 1 :
          return '取大';
        case 1 :
          return '不开仓';
      }
    }


  }
}
</script>

<style scoped>
.el-row {
    /*display: flex;*/
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.el-col {
    border-radius: 4px;
  }
.label {
    font-size: 16px;
    font-weight: 800;
    padding: 10px 10px;
  }
.title {
    font-size: 18px;
    font-weight: 800;
    padding: 10px 10px;
}
.text{
  padding: 0 10px;
}
</style>
