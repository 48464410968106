<template>
  <div class="app-container">
    <eHeader ref="header" :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe>
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column prop="userType" label="用户类型">
        <template slot-scope="scope">
          <span>{{ scope.row.userType==1?'普通用户':'合作伙伴' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="portraitImg" label="头像">
        <template slot-scope="scope">
          <img :src="scope.row.portraitImg==null?defaultImg:scope.row.portraitImg" class="el-avatar">
        </template>
      </el-table-column>
      <el-table-column prop="id" label="客户编号"/>
      <el-table-column prop="userName" label="用户名/昵称"/>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column prop="phone" label="手机号码">
        <template slot-scope="scope">
          <span>{{ scope.row.nationCode==null? scope.row.phone: scope.row.nationCode +'-'+scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column  label="推荐人" width="">
         <template slot-scope="scope">
            <span v-if="scope.row.belongUserName!=null"><br>{{scope.row.belongUserName}}</span>
            <span v-if="scope.row.belongUserName==null">----</span>
        </template>
      </el-table-column>
      <el-table-column label="返佣">
        <template slot-scope="scope">
          <span>{{scope.row.proportion * 100}}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit
            class="done-column"
            v-if="checkPermission(['ADMIN','COMMUNITYUSER_ALL','COMMUNITYUSER_EDIT','COMMUNITYUSER_VIEW'])"
            :data="scope.row"
            :sup_this="sup_this"
            @lock-head="lockHead"
          />
          <el-button style="margin-bottom: 5px;" v-if="getCommissionFlag(scope.row)" size="mini" type="danger" @click="commissionHandle(scope.row)">返佣设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <lock-dialog
      :lock-dialog-show="lockDialogShow"
      :is-lock="isLock"
      :data-id="currentId"
      @close-dialog="lockDialogShow = false"
    ></lock-dialog>
    <commisson-setting
      :user-info="currentUserInfo"
      :commission-list="commissionList"
      :dialog-show="commissonDialogShow"
      @close="closeCommissonDialog"
    ></commisson-setting>
  </div>
</template>

<script>
  import { getReturnComissionList } from '@/api/community/srCommunityUserInfo'
  import commissonSetting from '../../../../components/community/customer/cusmgr/commissionSetting'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/community/customer/cusmgr/header'
  import edit from '@/components/community/customer/cusmgr/edit'
  import lockDialog from '@/components/community/customer/cusmgr/lockDialog'

  export default {
    name:'cuslist',
    components: {
      eHeader,
      edit,
      lockDialog,
      commissonSetting,
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        defaultImg: 'https://alita.oss-cn-shenzhen.aliyuncs.com/image/e1fa398899014ec2984bb2d5bf27e28b.png',
        sites: [],
        belongUserId: '',
        lockDialogShow: false,
        isLock: false,
        currentId: 0,
        commissonDialogShow: false,
        commissonUserInfo: {},
        commissionList: [],
        currentUserInfo:{}
      }
    },
    computed: {

    },
    mounted() {
      this.belongUserId = this.$route.query.belongUserId
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = 'community/crm/tCommunityUserInfo'
        const sort = 'id,desc'
        this.params = { page: this.page, size: this.size, sort: sort }
        const query = this.query
        const userName = query.userName
        const phone = query.phone
        const siteId = query.siteId
        const belongUserId = this.belongUserId
        const email = query.email;
        const parentName = query.parentName;
        if (email !== '' && email !== null) {
          this.params['email'] = email
        }
        if (userName !== '' && userName !== null) {
          this.params['userName'] = userName
        }
        if (phone !== '' && phone !== null) {
          this.params['phone'] = phone
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if (belongUserId !== '' && belongUserId !== null) {
          this.params['belongUserId'] = belongUserId
        }
        // if (type && value) { this.params[type] = value }

        if (parentName !== '' && parentName !== null) {
          this.params['parentName'] = parentName
        }
        return true;
      },
      lockHead(rowData){
        this.isLock = rowData.lock == 1 ? true: false;
        this.currentId = rowData.id;
        this.lockDialogShow = true;
      },
      getOptionList(siteId){
        return getReturnComissionList({
          siteId: siteId,
        }).then((res) => {
          this.commissionList = res;
        })
      },
      getCommissionFlag(item){
        return item.openCommission && !item.parentName;
      },
      commissionHandle(item){
        this.currentUserInfo = item;
        this.getOptionList(item.siteId).then((res) => {
          this.commissonDialogShow = true;
        })

      },
      closeCommissonDialog(){
        this.commissonDialogShow = false;
      }
    }
  }
</script>

<style lang="less" scoped>
.done-column {
  /*min-width: 290px;*/
}
</style>
