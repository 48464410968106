<template>
  <div>
    <el-button style="margin-bottom: 5px;" v-if="checkPermission(['ADMIN','COMMUNITYUSER_ALL','COMMUNITYUSER_VIEW'])" size="mini" type="success" @click="view">查看</el-button>
     <el-button style="margin-bottom: 5px;" v-if="checkPermission(['ADMIN','COMMUNITYUSER_ALL','COMMUNITYUSER_LOCK'])" size="mini" type="danger" @click="lockHandle">{{ lockTex }}</el-button>

    <eDetail ref="detail" :sup_this="sup_this"/>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
import eDetail from './detail'
import checkPermission from '@/utils/permission'
export default {
  components: { eForm ,eDetail},
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  computed:{
    lockTex(){
      return this.data.lock == 1 ? '锁定头像': '解锁头像';
    },

  },
  methods: {
    checkPermission,
    lockHandle() {
      this.$emit('lock-head',this.data);
    },
    view() {
      const _this = this.$refs.detail
      _this.data = this.data
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
